import { render, staticRenderFns } from "./Call.vue?vue&type=template&id=57df8bf9&scoped=true&"
import script from "./Call.vue?vue&type=script&lang=js&"
export * from "./Call.vue?vue&type=script&lang=js&"
import style0 from "./Call.vue?vue&type=style&index=0&id=57df8bf9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57df8bf9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBtn,VContainer,VDivider,VIcon,VOverlay,VProgressCircular,VSnackbar})
